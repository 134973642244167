<!--生产任务查询-->
<template>
    <div class="productionTaskSearch">
        <currencyTable
            :table-data="tableData"
            ref="currencyTable"
            @handleViewDetail="handleViewDetail"
            @handleView="handleView"
            @handleViewFwd="handleViewFwd"
            @sortChange="sortChange"
        >
            <div slot="queryCriteria" class="queryCriteria">
                <div class="query-box">
                    <div class="queryList">
                        <h4>场站：</h4>
                        <el-select
                            v-model="station_name"
                            multiple
                            collapse-tags
                            placeholder="全部"
                            clearable
                        >
                            <el-option
                                v-for="item in stationList"
                                :key="item.station_id"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="queryList">
                        <h4>生产状态：</h4>
                        <el-select
                            v-model="product_status"
                            multiple
                            collapse-tags
                            placeholder="全部"
                            clearable
                        >
                            <el-option label="待生产" value="1"></el-option>
                            <el-option label="生产中" value="2"></el-option>
                            <el-option label="已完成" value="3"></el-option>
                            <el-option label="暂停" value="4"></el-option>
                        </el-select>
                    </div>
                    <div class="el_left conditionQuery">
                        <el-input placeholder="请输入搜索内容..." v-model="search" class="input-with-select">
                        </el-input>
                    </div>
                    <div class="btns">
                        <el-button size="mini" @click="query">
                            查询
                        </el-button>
                    </div>
                </div>
                <div class="top-text">
                    当前任务方量{{ baseData.sum_volumn }}方，已完成{{ baseData.sum_finish_volumn }}方  完成率{{ baseData.rate }}%
                </div>
            </div>
        </currencyTable>
    </div>
</template>

<script>
import currencyTable from '@/components/table/index.vue';
export default {
    name: 'production-task-search',
    components: { currencyTable },
    data() {
        return {
            station_id: '',
            stationList: '', // 场站列表
            station_name: '',
            product_status: '',
            search: '',
            orderby: '',
            baseData: {
                sum_volumn: 0, // 总的任务方量
                sum_finish_volumn: 0, // 已完成的任务方量
                rate: 0, // 完成率
            },
            tableData: {
                headerData: [{
                    field: 'task_number',
                    title: '任务单号',
                    width: 200,
                    link: {
                        method: 'handleViewDetail',
                    },
                    sortable: 'custom',
                }, {
                    field: 'customer_name',
                    title: '客户名称',
                    width: 150,
                    sortable: 'custom',
                }, {
                    field: 'sgdw_name',
                    title: '施工单位',
                    width: 150,
                    sortable: 'custom',
                }, {
                    field: 'project_name',
                    title: '工程名称',
                    width: 150,
                    sortable: 'custom',
                }, {
                    field: 'place_detail',
                    title: '施工部位',
                    width: 150,
                    sortable: 'custom',
                }, {
                    field: 'product_number',
                    title: '产品标号',
                    width: 110,
                    sortable: 'custom',
                }, {
                    field: 'quantity',
                    title: '任务方量',
                    width: 110,
                    sortable: 'custom',
                }, {
                    field: 'grand_total_complete_quantity',
                    title: '发货方量',
                    width: 110,
                    sortable: 'custom',
                }, {
                    field: 'arrival_time',
                    title: '用料时间',
                    width: 150,
                    sortable: 'custom',
                }, {
                    field: 'product_time',
                    title: '开盘时间',
                    width: 150,
                    sortable: 'custom',
                }, {
                    field: 'pouring_name',
                    title: '浇筑方式',
                }, {
                    field: 'slump',
                    title: '坍落度',
                }, {
                    field: 'product_status',
                    title: '状态',
                }, {
                    field: 'station_name',
                    title: '场站',
                    sortable: 'custom',
                }, {
                    field: 'product_count',
                    title: '发货单数',
                    link: {
                        method: 'handleViewFwd',
                    },
                }, {
                    title: '任务配比',
                    btns: [
                        {
                            name: '查看',
                            btnClass: 'viewbtn',
                            method: 'handleView',
                        },
                    ],
                }],
                isShowNo: true,
                isConditionQuery: true,
                generalQuery: false,
                requestUrl: '/production/productorder/product_task_page',
                requestMethod: 'get',
                requestParameter: '',
            },
        };
    },

    created() {
        this.getStationList();
        this.getRateData();
    },
    methods: {
        // 获取场站列表
        getStationList() {
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_list')
                .then(data => {
                    if (data.length > 0) {
                        this.stationList = data;
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        query() {
            this.tableData.requestParameter = '&station_name='
                + this.station_name + '&product_status='
                + this.product_status.join() + '&search=' + this.search
                + '&orderby=' + this.orderby;

            this.$refs.currencyTable.getTableData();
            this.getRateData();
        },
        getRateData() {
            this.$axios
                .get('/interfaceApi/production/productorder/product_task_page?pageindex=1&pagesize=0' + this.tableData.requestParameter)
                .then(data => {
                    this.baseData.sum_volumn = data.sum_volumn;
                    this.baseData.sum_finish_volumn = data.sum_finish_volumn;
                    this.baseData.rate = (data.rate * 100).toFixed(2);
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 点击任务单号查看详情
        handleViewDetail(item) {
            this.$YWShowLayer('FormCode=PT202001142239040232&IsView=true', item.ptid);
        },
        // 点击发货单号查看详情
        handleViewFwd(item) {
            this.$toast({
                title: true,
                text: '任务配比',
                type: 'eject',
                width: '13rem',
                height: '9rem',
                t_url: 'productionManagement/productionTaskSearch/invoiceList',
                extr: {
                    task_number: item.task_number,
                },
            });
        },
        // 查看
        handleView(item) {
            this.$toast({
                title: true,
                text: '任务配比',
                type: 'eject',
                width: '11rem',
                height: '9rem',
                t_url: 'productionManagement/productionTaskSearch/taskRadioInfo',
                extr: {
                    task_number: item.task_number,
                },
            });
        },
        // 服务端通用排序
        sortChange({ prop, order }) {
            prop = prop === 'product_number' ? 'strength_grade_name' : prop;
            if (order) {
                const sort = order === 'ascending' ? 'asc' : 'desc';
                this.orderby = prop + ' ' + sort;
            } else {
                this.orderby = '';
            }
            this.query();
        },
    },
};
</script>

<style lang="stylus" scoped>
    .el_left
        width calc(100% - 0.5rem);
    /deep/ .el_left
        width calc(100% - 0.5rem);
    .el-link
        color: blue;
        text-decoration: underline;
        cursor: pointer;
        &:after
            display none!important;
    /deep/ .el-link
        color: blue;
        text-decoration: underline;
        cursor: pointer;
        &:after
            display none!important;
    .productionTaskSearch
        .el-table
            height: calc(100% - 1.6rem);
        /deep/ .el-table
            height: calc(100% - 1.6rem);
        .viewbtn
            border 0;
            background: none;
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        /deep/ .viewbtn
            border 0;
            background: none;
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        .queryCriteria
            width 100%;
            float left
            .query-box
                width 100%;
                display flex
                .conditionQuery
                    width 2.6rem;
                    margin-right 0.2rem;
                .queryList
                    margin-right: 0.15rem
                    display flex
                    h4
                        white-space nowrap
                        line-height: 0.36rem
                        font-size: 0.16rem
                    .btns
                        font-size: 0.14rem;
                        height: 0.36rem;
                        line-height: 0.36rem;
                        margin: 0.05rem 0;
                        color: #fff;
                        border: none;
                        background: #2978ff;
                        width: 0.66rem;
                        border-radius: 2px;
                        cursor: pointer;
                        margin-right: 0.2rem;
                    .time-range-inp
                        width 3.8rem!important;
                        /deep/ .el-range__close-icon
                            display flex
                            align-items center
            .top-text
                text-align center
                font-size 0.24rem;
                padding 0.2rem;
                color rgba(237,45,49,1)
</style>
